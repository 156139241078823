.draftJsLinkifyPlugin__link__2ittM, .draftJsLinkifyPlugin__link__2ittM:visited {
  text-decoration: none;
  font-family: 'Lato', sans-serif;
  font-size: 1em;
  color: #FF5C39 ;

  border-bottom: solid 1px transparent;
  transition: border-bottom 0.2s ease-out;
  cursor: text ;
}

.draftJsLinkifyPlugin__link__2ittM:hover, .draftJsLinkifyPlugin__link__2ittM:focus {
  color: #FF5C39;
  outline: 0; /* reset for :focus */
  cursor: pointer ;
  border-bottom: solid 1px #FF5C39;
}

.preview .draftJsLinkifyPlugin__link__2ittM {
  color: #FF5C39 !important;
  outline: 0 !important; /* reset for :focus */
  cursor: pointer !important;
}
.preview .draftJsLinkifyPlugin__link__2ittM:hover {
  color: #FF5C39 !important;
  outline: 0 !important; /* reset for :focus */
  cursor: pointer !important;
  border-bottom: solid 1px #FF5C39 !important;

}

.editing .draftJsLinkifyPlugin__link__2ittM {
  text-decoration: none !important;
  font-family: 'Lato', sans-serif!important;
  font-size: 1em!important;
  color: #FF5C39 !important;
  border-bottom: solid 1px transparent!important;
  transition: border-bottom 0.2s ease-out!important;
  cursor: text !important;
}

/*.draftJsLinkifyPlugin__link__2ittM:active {*/
  /*color: #FF5C39;*/
/*}*/
